import React, { Component } from 'react';
import api from '../api';
import { observer } from "mobx-react"

class UserPage extends Component {
    static displayName = UserPage.name;

    constructor(props) {
        super(props);
        this.state = {
            currentPassword: "",
            newPassword: "",
            newPasswordRepeat: "",
        };
        this.changePassword = this.changePassword.bind(this);
    }

    async changePassword() {
        if (this.state.newPassword !== this.state.newPasswordRepeat) {
            window.alert("Пароли не совпадают");
            return;
        }
        let response = await await api.users.changeUserPassword(this.state.currentPassword, this.state.newPassword);
        if (response.ok) {
            window.alert("Пароль успешно изменён");
            this.setState({
                currentPassword: "",
                newPassword: "",
                newPasswordRepeat: ""
            });
        }
        else {
            let errors = await response.json();
            //TODO
            //this.appStore.createToast({
            //    head: 'Ошибка',
            //    body: errors.map(m => <div key={m.description}>{m.description}</div>)
            //});
        }
    }

    render() {
        return (
            <div>
                <h1 className="mb-3">Смена пароля</h1>
                <div className="mb-3">
                    <label className="form-label">Текущий пароль</label>
                    <input
                        onChange={(e) => { this.setState({ currentPassword: e.target.value }) }}
                        value={this.state.currentPassword}
                        type="password" className="form-control" />
                </div>
                <div className="mb-3">
                    <label className="form-label">Новый пароль</label>
                    <input
                        onChange={(e) => { this.setState({ newPassword: e.target.value }) }}
                        value={this.state.newPassword}
                        type="password" className="form-control" />
                </div>
                <div className="mb-3">
                    <label className="form-label">Повторите пароль</label>
                    <input
                        onChange={(e) => { this.setState({ newPasswordRepeat: e.target.value }) }}
                        value={this.state.newPasswordRepeat}
                        type="password" className="form-control" />
                </div>
                <div className="mb-3">
                    <button onClick={this.changePassword} className="btn btn-primary">Сменить пароль</button>
                </div>
            </div>
        );
    }
}

export default observer(UserPage)