import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import api from '../api';
import UserStore from '../stores/userStore';
import { inject, observer } from "mobx-react";
import { withRouter } from '../helpers/withRouter';

class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.userStore = this.props.userStore;

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    async handleLogout() {
        if (window.confirm("Подтвердите выход")) {
            let response = await api.users.logout();
            if (response.ok) {
                this.userStore.changeUser(null);
                this.props.router.navigate(`/`);
            }
        }
    }

    render() {
        let isActive = (...routes) => {
            return routes.some(m => this.props.router.location.pathname.startsWith(m)) ? true : undefined;
        };

        let isInRole = (role) => {
            return this.userStore.user.roles.includes(role);
        }

        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
                    <NavbarBrand tag={Link} to="/">Аккаунт</NavbarBrand>
                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                        <ul className="navbar-nav flex-grow">
                            {this.userStore.user
                                ? <>
                                    <NavItem>
                                        <NavLink tag={Link} to="/user" active={isActive("/user")}>Пользователь</NavLink>
                                    </NavItem>
                                    {isInRole(UserStore.AdminRole)
                                        ? <NavItem>
                                            <NavLink tag={Link} to="/admin" active={isActive("/admin")}>Админка</NavLink>
                                        </NavItem>
                                        : <></>}
                                    <NavItem>
                                        <NavLink onClick={this.handleLogout} style={{ cursor: "pointer" }} >Выйти</NavLink>
                                    </NavItem>
                                </>
                                : <NavItem>
                                    <NavLink tag={Link} to="/login" active={isActive("/login")}>Войти</NavLink>
                                </NavItem>
                            }
                        </ul>
                    </Collapse>
                </Navbar>
            </header>
        );
    }
}

export default inject('userStore')(withRouter(observer(NavMenu)));