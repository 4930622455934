import React, { Component } from 'react';
import { observer } from "mobx-react"
import AdminStore from '../stores/adminStore';
import UserStore from '../stores/userStore';

class AdminPage extends Component {
    static displayName = AdminPage.name;

    constructor(props) {
        super(props);
        this.adminStore = new AdminStore();
        this.adminStore.getUserList();
        this.adminStore.getRoles();
    }

    render() {
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">Имя пользователя</th>
                        <th scope="col">Email</th>
                        <th scope="col">Роли</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {this.adminStore.userList.map(user =>
                        <tr key={user.id}>
                            <td>{user.userName}</td>
                            <td>{user.email ? user.email : "-"}</td>
                            <td>{user.roles.join(', ')}</td>
                            <td>
                                {user.roles.includes(UserStore.AdminRole)
                                    ? <button className="btn btn-sm btn-danger" onClick={() => { this.adminStore.removeUserFromRole(user.id, UserStore.AdminRole) }}>↓</button>
                                    : <button className="btn btn-sm btn-success" onClick={() => { this.adminStore.addUserToRole(user.id, UserStore.AdminRole) }}>↑</button>}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }
}

export default observer(AdminPage)