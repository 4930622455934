import  LoginPage  from "./pages/LoginPage";
import UserPage from "./pages/UserPage";
import AdminPage from "./pages/AdminPage";
import { Home } from "./components/Home";

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/login',
        element: <LoginPage />
    },
    {
        path: '/user',
        element: <UserPage />
    },
    {
        path: '/admin',
        element: <AdminPage />
    }
];

export default AppRoutes;
