const api = {
    role: {
        getRoleList: async () => {
            let response = await fetch(`api/role/getRoleList`);
            return response;
        },
        addUserToRole: async (userId, role) => {
            let response = await fetch(`api/role/addUserToRole`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    userId: userId,
                    value: role,
                }),
            });
            return response;
        },
        removeUserFromRole: async (userId, role) => {
            let response = await fetch(`api/role/removeUserFromRole`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    userId: userId,
                    value: role,
                }),
            });
            return response;
        }
    },
    admin: {
        getUserList: async () => {
            let response = await fetch(`api/admin/getUserList`);
            return response;
        },
        addUser: async (userName, email, password) => {
            let response = await fetch(`api/admin/addUser`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    userName: userName,
                    email: email,
                    password: password,
                }),
            });
            return response;
        },
    },
    users: {
        changeUserPassword: async (currentPassword, newPassword) => {
            let response = await fetch(`api/user/changeuserpassword`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    currentPassword: currentPassword,
                    newPassword: newPassword,
                }),
            });
            return response;
        },
        login: async (formData) => {
            let response = await fetch(`api/user/login`, {
                method: 'post',
                body: formData
            });
            return response;
        },
        logout: async () => {
            let response = await fetch(`api/user/logout`, { method: "post" });
            return response;
        },
        isSignedIn: async () => {
            let response = await fetch(`api/user/isSignedIn`);
            return response;
        }
    }
}
export default api;