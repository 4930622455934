import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import UserStore from './stores/userStore';
import { Layout } from './components/Layout';
import './custom.css';
import { withRouter } from './helpers/withRouter'
import { Provider, observer } from 'mobx-react';

class App extends Component {
    static displayName = App.name;

    async componentDidMount() {
        let isLogged = await this.userStore.getCurrentUser();
        if (!isLogged) {
            console.log(this.props);
            if (this.props.router.location.pathname !== "/login" &&
                this.props.router.location.pathname !== "/") {
                let currentAddress = this.props.router.location.pathname + this.props.router.location.search;
                this.props.router.navigate(`/login?returnUrl=${encodeURIComponent(currentAddress)}`, {replace: true});
            }
        }
    }

    constructor(props) {
        super(props);

        this.userStore = new UserStore();
    }

    render() {
        return (
            <Provider userStore={this.userStore}>
                <Layout>
                    <Routes>
                        {AppRoutes.map((route, index) => {
                            const { element, ...rest } = route;
                            return <Route key={index} {...rest} element={element} />;
                        })}
                    </Routes>
                </Layout>
            </Provider>
        );
    }
}

export default withRouter(observer(App));