import { action, makeObservable, observable, runInAction } from 'mobx';
import api from '../api';

export default class AdminStore {
    constructor() {
        makeObservable(this, {
            userList: observable,
            loading: observable,
            getUserList: action,
            getRoles: action,
        });
    }

    userList = [];
    loading = true;
    roles = [];

    getRoles = async () => {
        this.loading = true;
        let response = await api.role.getRoleList();
        if (response.ok) {
            const data = await response.json();
            runInAction(() => {
                this.roles = data;
            });
        }
    }

    addUserToRole = async (userId, role) => {
        let response = await api.role.addUserToRole(userId, role);
        if (response.ok) {
            this.getUserList();
        }
    }

    removeUserFromRole = async (userId, role) => {
        let response = await api.role.removeUserFromRole(userId, role);
        if (response.ok) {
            this.getUserList();
        }
    }

    getUserList = async () => {
        this.loading = true;
        let response = await api.admin.getUserList();
        if (response.ok) {
            const data = await response.json();
            runInAction(() => {
                this.userList = data;
                this.loading = false;
            });
        }
    }
}