import { action, makeObservable, observable, runInAction } from 'mobx';
import api from '../api';

export default class UserStore {
    constructor() {
        makeObservable(this, {
            user: observable,
            changeUser: action,
            getCurrentUser: action,
        });
    }

    static AdminRole = 'Administrator';

    user = null;

    changeUser = (user) => {
        this.user = user;
    }

    getCurrentUser = async () => {
        let response = await await api.users.isSignedIn();
        if (!response.ok) {
            return false;
        }
        const user = await response.json();
        runInAction(() => {
            this.user = user;
        })
        return true;
    }
}